import React from "react";
import Layout from "../components/layout";
import useFetch from "starkeydigital/React/Hooks/useFetch";
import Accordion from "../../../../StarkeyDigital/StarkeyDigital.Components/React/Components/accordion";
import PulseLoader from "react-spinners/PulseLoader";
import HeaderDivideTextLeft from "../components/headerDivideTextLeft";
import BookLong from "../components/bookLong";
interface props {}

interface Data {
  data: VideoContent[];
}
interface VideoContent {
  id: number;
  date_created: Date;
  date_updated: Date;
  video: string;
  title: string;
}
const Resources: React.FC<props> = (props) => {
  const videoUrl = "https://cms.starkeydigital.com/assets/";

  const {data, loading, error} = useFetch<Data>(
    "https://cms.starkeydigital.com/items/atd_video",
  );

  React.useEffect(() => {
    if (data?.data) {
      data.data.sort((a, b) => a.title.localeCompare(b.title));
    }
  }, [data]);

  return (
    <Layout
      titleText="Resources"
      titleText2=""
      slogan="We provide a selection of video clips below highlighting common topics.
      You can also review Alison’s book, Inside Knowledge."
      includeRandomTestimonial={false}
    >
      <HeaderDivideTextLeft
        header="Videos"
        column="col"
        style={{marginTop: "115px"}}
      />

      {!data && <PulseLoader />}

      {data && (
        <div style={{marginTop: "35px"}}>
          <Accordion>
            {console.log(data)}
            {data.data.map((video, index) => (
              <Accordion.Item key={index} title={video.title}>
                <div className="flex justify-content-center">
                  <video
                    src={`${videoUrl}${video.video}`}
                    controls
                    height="400px"
                    width="100%"
                  />
                </div>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      )}

      <BookLong />
    </Layout>
  );
};
export default Resources;
