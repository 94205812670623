import styled from "@emotion/styled";
import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import Column from "starkeydigital/React/Components/column";
import Row from "starkeydigital/React/Components/row";
import Paypal from "gatsby-plugin-paypal";
interface props {}
const BookLong: React.FC<props> = (props) => {
  const Shipping = styled.span`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 133% */
    text-transform: lowercase;
    color: #aeb4be;
  `;

  return (
    <Row style={{margin: "115px 0"}}>
      <Column class="col-md-6">
        <StaticImage src="../images/book.png" alt="book" />
      </Column>
      <Column class="col-md-6">
        <h1 style={{textAlign: "left"}}>INSIDE KNOWLEDGE</h1>
        <h2 style={{color: "#6AB2E2"}}>
          How Women Can Thrive In Professional <br /> Service Firms
        </h2>
        <div className="divider" style={{margin: "35px 0"}} />
        <h2>
          £16.99 <Shipping>* free shipping available</Shipping>
        </h2>

        <Paypal
          style={{
            shape: "rect",
            color: "blue",
            layout: "horizontal",
            label: "paypal",
          }}
          amount={16.99}
          currency="GBP"
        />

        <p>
          <strong>Details:</strong>
        </p>

        <p>
          "Alison's new book Inside Knowledge provides practical guidance for
          women working in professional service firms who aspire to achieve
          their full potential in what have traditionally been male working
          environments."
        </p>
      </Column>
    </Row>
  );
};
export default BookLong;
